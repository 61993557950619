(function () {
  'use strict';

  angular
  .module('neo.home.user.profile')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.userProfile', {
      url: '/users/:userId/profile',
      views: {
        '': {
          templateUrl: 'home/user/profile/profile.tpl.html',
          controller: 'ProfileCtrl',
          controllerAs: 'vm',
          resolve: {
            profile: function (UserProfile, $stateParams) {
              return UserProfile.get({id: $stateParams.userId}).$promise;
            }
          }
        },
        'details@home.userProfile': {
          templateUrl: 'home/user/profile/details.tpl.html'
        },
        'actions@home.userProfile': {
          templateUrl: 'components/actionButton/action-button.tpl.html',
          controller: 'ActionButtonCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
}());
